@import '../abstracts/mixins';

.homeSearchContainer {
  background: white;
  border: 2px solid transparent;
  border-radius: 30px;
  padding: 10px;
  box-shadow: 0px 4px 16px 0px #00000029;
  display: flex;
  justify-content: flex-start;
  flex-wrap: nowrap;
  @include media(mobile) {
    margin-top: 25px;
    padding: 10px 13px;
  }
  &.active {
    border: 2px solid #463264;
  }
  .homeSearchIcon {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100%;
    width: 25px;
    height: 25px;
    margin: 10px;
    background-image: url('/static/img/home/icon_search.svg');
    @include media(mobile) {
      width: 15px;
      height: 15px;
      margin: 5px;
    }
  }
}
