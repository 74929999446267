@import '../abstracts/mixins';
@import '../abstracts/variables';

.sunshineColor {
  color: #ffd750 !important;
}

.buyherobtnContainer {
  display: flex;
  flex-direction: row;
  margin: 30px 0px 0px;
  .btnLarge:last-child {
    margin-bottom: 0px;
  }
  @media only screen and (min-width: 769px) and (max-width: 1300px) {
    flex-direction: column;
    flex-wrap: wrap;
    align-items: start;
  }
  @include media(tablet) {
    flex-direction: column;
    flex-wrap: wrap;
    align-items: start;
  }
  @include media(mobile) {
    flex-direction: column;
    justify-content: center;
    align-items: start;
  }
  .btnLarge {
    background-color: #ff7350;
    color: #ffffff;
    margin-bottom: 32px;
    border-radius: 24px;
    text-transform: none;
    font-weight: 700;
    font-size: 24px;
    font-family: 'Poppins';
    @include media(mobile) {
      width: 100%;
    }
  }
  .startBuyButton {
    width: 376px;
    height: 76px;
    color: #fff;
    margin-right: 20px;
    padding: 8px 18px;
    background-color: #ff7350;
    color: #ffffff;
    &:hover {
      background-color: #ffffff;
      color: #ff7350;
    }
    @media only screen and (min-width: 950px) and (max-width: 1042px) {
      width: 340px;
    }
    @include media(tablet) {
      width: 340px;
    }
    @include media(mobile) {
      font-size: 22px;
      width: auto;
      margin-right: 0px;
    }
  }
  .talkToUsButton {
    width: 196px;
    height: 76px;
    margin-left: 10px;
    height: 76px;
    color: #ff7350;
    border: 2px solid #ff7350;
    background-color: #fff;
    &:hover {
      color: #ff7350;
    }
    @include media(tablet) {
      margin-left: 0px;
      margin-bottom: 0px;
    }
    @include media(mobile) {
      margin-left: 0px;
      margin-bottom: 0px;
    }
  }
}

.baseBtn {
  width: 244px;
  min-width: 190px;
  height: 64px;
  background-color: #faf5f0;
  margin-bottom: 32px;
  border-radius: 8px;
  text-transform: none;
  text-decoration: none;
  font-weight: 600;
  font-size: 24px;
  font-family: 'Poppins';
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  cursor: pointer;
  &:hover {
    background-color: #195a50;
    color: #faf5f0;
  }
  @include media(mobile) {
    padding: 16px 20px;
  }
  &.pineGreen_txt {
    background-color: #faf5f0;
    color: #195a50 !important;
    &:hover {
      background-color: #195a50;
      color: #faf5f0 !important;
      border: 1px solid #faf5f0;
    }
  }
  &.baseBtnPineGreen {
    width: 360px;
    height: 72px;
    color: #faf5f0;
    background-color: #195a50 !important;
    margin-right: 24px;
    @include media(mobile) {
      margin-right: 0px;
    }
  }
  &.talkToUsBorder {
    width: 195px;
    height: 72px;
    color: #195a50;
    background-color: #fff;
    border: 2px solid #195a50;
    margin-right: 24px;
    @include media(mobile) {
      width: 360px;
      margin-right: 0px;
    }
  }
}
.homeAdvantage {
  padding: 64px 100px;
  @media only screen and(max-width: 1200px) {
    padding: 32px 80px;
  }
  .homeAdvantageContainer {
    @include media(mobile) {
      margin: 16px auto 0px auto;
    }
  }
}
.advantageContainer {
  display: flex;
  padding: 0px;
  max-width: 1330px;
  margin: 56px auto 0px auto;
  @media only screen and (min-width: 950px) and (max-width: 1050px) {
    flex-direction: column;
  }
  @include media(tablet) {
    flex-direction: column;
  }
  @include media(mobile) {
    flex-direction: column;
  }
}
.advantageHeading {
  font-family: 'Sharp Grotesk Semibold', sans-serif;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 56px;
  letter-spacing: 0em;
  text-align: center;
  margin: auto;
  @media only screen and (min-width: 950px) and (max-width: 1042px) {
    font-size: 32px;
    line-height: 40px;
  }
  @include media(tablet) {
    font-size: 32px;
    line-height: 40px;
  }
  @include media(mobile) {
    font-size: 32px;
    line-height: 40px;
  }
  @media only screen and (min-width: 390px) {
    font-size: 28px;
    line-height: 40px;
  }
}
.advantageHeader {
  @include media(mobile) {
    margin-bottom: 16px !important;
  }
}
.advantagePointContainer {
  width: 392px;
  height: auto !important;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  border-radius: 32px;
  margin: 0px 16px;
  @media only screen and (min-width: 950px) and (max-width: 1042px) {
    min-height: 100%;
  }
  @include media(tablet) {
    min-height: 100%;
  }
  @include media(mobile) {
    min-height: 100%;
  }
}
.advantagePointheading {
  @include media(mobile) {
    font-family: Sharp Grotesk Semibold !important;
    font-style: normal !important;
    letter-spacing: 0em !important;
    font-weight: normal !important;
    margin: 8px 0 16px 0 !important;
  }
  @media only screen and (min-width: 380px) {
    font-size: 22px !important;
  }
}
.advantageIcon {
  width: 116px;
  height: 116px;
  @media only screen and (min-width: 950px) and (max-width: 1042px) {
    width: 64px;
    height: 64px;
  }
  @include media(tablet) {
    width: 64px;
    height: 64px;
  }
  @include media(mobile) {
    width: 64px;
    height: 64px;
  }
}
.reasonIcon svg {
  width: 40px;
  height: 40px;
  @media only screen and (min-width: 950px) and (max-width: 1042px) {
    width: 32px;
    height: 32px;
  }
  @include media(tablet) {
    width: 32px;
    height: 32px;
  }
  @include media(mobile) {
    width: 32px;
    height: 32px;
  }
}
.featuredImage_block {
  position: relative;
  padding: 0px;
  &.coverSticker_block {
    margin: 100px 0px 50px;
    @include media(mobile) {
      margin: 50px 0px 16px;
    }
  }
  .container_wrap {
    display: flex;
    align-items: center;
    @include media(mobile) {
      flex-direction: column;
    }
    .text_container {
      display: flex;
      flex: 1;
      flex-direction: column;
      text-align: left;
      padding: 0px 100px;
      @media only screen and (min-width: 950px) and (max-width: 1042px) {
        width: 50%;
        padding: 0px 32px;
      }
      @include media(tablet) {
        width: 50%;
        padding: 0px 32px;
      }
      @include media(mobile) {
        width: 100%;
        padding: 0px 32px;
        margin: 48px 0 72px;
      }
      .heading {
        font-size: 24px;
        margin-bottom: 16px;
        font-family: 'Poppins', sans-serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: left;
      }
      .subHeading {
        margin-bottom: 24px;
        font-family: 'Sharp Grotesk Semibold', sans-serif;
        font-size: 48px;
        font-style: normal;
        font-weight: 600;
        line-height: 56px;
        letter-spacing: 0em;
        text-align: left;
        @media only screen and (min-width: 950px) and (max-width: 1042px) {
          font-size: 32px;
          line-height: 40px;
        }
        @include media(tablet) {
          font-size: 32px;
          line-height: 40px;
        }
        @include media(mobile) {
          font-size: 32px;
          line-height: 40px;
        }
      }
      .mainText {
        font-family: 'Poppins', sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: left;
        max-width: 750px;
      }
    }
    .imageWrap {
      position: relative;
      display: flex;
      flex: 1;
      .feturedImage {
        width: 100%;
      }
      @include media(tablet) {
        width: 100%;
      }
      @include media(mobile) {
        width: 100%;
      }
      .coverStickerBase {
        width: 100%;
        height: 100%;
        z-index: 1;
        position: absolute;
        top: 0px;
        @include media(mobile) {
          display: none;
        }
        .SunnyKitchenSticker {
          position: absolute;
          top: -8%;
          left: -5%;
        }
        .NearByParksSticker {
          position: absolute;
          top: -15%;
          right: 4%;
        }
        .ReadyToHostSticker {
          position: absolute;
          bottom: -5%;
          right: 15%;
        }
      }
    }
    .imageWrap {
      &.imageBg {
        width: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        min-height: 620px;
        @include media(mobile) {
          min-height: 664px;
        }
        .feturedImage {
          display: none;
          @include media(mobile) {
            display: block;
          }
        }
        @include media(mobile) {
          background: none;
        }
        &.buyPersonalizeImageBg {
          width: 650px;
          height: 690px;
          background-image: url('/static/img/landing_pages/buy-page-move-in-desktop.png');
          @media only screen and (min-width: 950px) and (max-width: 1042px) {
            width: 500px;
            height: 667px;
            background-image: url('/static/img/landing_pages/buy-page-move-in-tablet-mini.png');
          }
          @include media(tablet) {
            width: 500px;
            height: 640px;
            background-image: url('/static/img/landing_pages/buy-page-move-in-tablet.png');
          }
          @include media(mobile) {
            width: 428px;
            height: 664px;
            background-image: url('/static/img/landing_pages/buy-page-move-in-mobile.png');
          }
        }

        &.buyExperienceImageBg {
          width: 650px;
          height: 620px;
          background-image: url('/static/img/landing_pages/buy-page-experience-desktop.png');
          @media only screen and (min-width: 950px) and (max-width: 1042px) {
            width: 500px;
            height: 667px;
            background-image: url('/static/img/landing_pages/buy-page-experience-tablet-mini.png');
            //background-position: 18%;
          }
          @include media(tablet) {
            width: 500px;
            height: 640px;
            background-image: url('/static/img/landing_pages/buy-page-experience-tablet.png');
          }
          @include media(mobile) {
            width: 428px;
            height: 664px;
            background-image: url('/static/img/landing_pages/buy-page-experience-mobile.png');
          }
        }
      }
    }
  }
  &.reverse {
    .container_wrap {
      flex-direction: row-reverse;
      @include media(mobile) {
        flex-direction: column;
        flex-direction: column-reverse;
      }
    }
  }
  &.toriiPlum_bg {
    background-color: #463264;
    .container_wrap {
      .text_container {
        .heading {
          color: #ffd750;
        }
        .subHeading,
        .mainText {
          color: #fff;
        }
      }
    }
  }
  &.merlot_bg {
    background-color: #822841;
    .container_wrap {
      .text_container {
        .heading {
          color: #faf5f0;
        }
        .subHeading,
        .mainText {
          color: #fff;
        }
      }
    }
  }
}

ul.checkCircle_listing {
  list-style: none;
  padding: 0px;
  margin: 24px 0px 0px;
  li {
    font-family: Sharp Grotesk Semibold;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    list-style: none;
    display: flex;
    align-items: center;
    margin: 10px 0px;
  }
  svg {
    margin-right: 5px;
  }
}
